//askFor quiz dont work because current user not initialized
import "./Firebase.scss";
import { initializeApp } from "firebase/app";
import { getPerformance, trace } from "firebase/performance";
import { getAnalytics } from "firebase/analytics";
import { doc, getFirestore, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { useQuery, useQueryClient } from "react-query";
import React from "react";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendEmailVerification,
} from "firebase/auth";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";

const firebaseConfig = {
  apiKey: "AIzaSyCq0WgDcpq8pVHbJs7rdKgs5kIxIuyeuxQ",
  authDomain: "quitop-cb2ee.firebaseapp.com",
  projectId: "quitop-cb2ee",
  storageBucket: "quitop-cb2ee.appspot.com",
  messagingSenderId: "792926500609",
  appId: "1:792926500609:web:caddbd6a552ede20a4cd00",
  measurementId: "G-YCD89DCGTX",
};

//init firebase components
const app = initializeApp(firebaseConfig);
let perf = false;
let analytics = false;
if (localStorage.getItem("accept_cookies")) {
  perf = getPerformance(app);
  analytics = getAnalytics(app);
} else {
  // Define a no-op object that simulates the `perf` methods but does nothing
  perf = {
    trace: () => ({
      start: () => {},
      stop: () => {},
    }),
    // Add other methods as necessary
  };
}

const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth(app);
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LdflDIqAAAAAAyvmMfRgHEIfS1vgxTgjWdFQP7g"),
  isTokenAutoRefreshEnabled: true,
});
export { db, functions, perf };
const MySwal = withReactContent(
  Swal.mixin({
    allowEscapeKey: false,
    allowOutsideClick: false,
    customClass: {
      popup: "my-popup-class", // Classe personnalisée pour la boîte
      title: "my-title-class", // Classe personnalisée pour le titre
      confirmButton: "my-confirm-button-class", // Classe personnalisée pour le bouton de confirmation
    },
  })
);

// https callable func
const createProfile = httpsCallable(functions, "createUser");

//create context
const AuthContext = React.createContext();

const createTrace = (traceName) => {
  if (perf) {
    return trace(perf, traceName);
  } else {
    console.warn("Perf tracking is disabled");
    return {
      start: () => {},
      stop: () => {},
      putAttribute: () => {},
    };
  }
};

//get user
function useAuth() {
  return useQuery("currentUser", () => {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  });
}

//usage
export const AuthProvider = ({ children }) => {
  const { t } = useTranslation();
  const { data: currentUser, isLoading } = useAuth();
  const queryClient = useQueryClient();

  function timeStampGap(now, Firestore) {
    const time1 = Firestore.seconds * 1000 + Firestore.nanoseconds / 1000000;
    const time2 = now;
    const difInMillis = Math.abs(time2 - time1);
    const difInMinute = difInMillis / (1000 * 60);

    return difInMinute;
  }

  const signup = async (email, password, username) => {
    const Perf_signup = createTrace(perf, "signup");
    Perf_signup.start();
    try {
      const user = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(user.user);
      await createProfile({ displayName: username });
      await signOut(auth);
      Perf_signup.stop();
      return;
    } catch (error) {
      console.error(`${error.message} + ${error.code}`);
      let customError = new Error();
      switch (error.code) {
        case "auth/email-already-in-use":
          customError.message = t("error.email_already_used");
          break;
        case "auth/invalid-email":
          customError.message = t("error.invalid_email");
          break;
        case "auth/weak-password":
          customError.message = t("error.weak_password");
          break;
        case "auth/too-many-requests":
          customError.message = t("error.too_much_request");
          break;

        default:
          customError.message = t("error.unable_register");
          break;
      }
      customError.code = error.code;
      Perf_signup.putAttribute("error", customError.message);
      Perf_signup.stop();
      throw customError;
    }
  };

  const login = async (email, password) => {
    const Perf_login = createTrace(perf, "login");
    Perf_login.start();
    try {
      const user = await signInWithEmailAndPassword(auth, email, password);
      queryClient.invalidateQueries("currentUser");
      if (!user.user.emailVerified) {
        let customMessage = new Error(t("error.unverified_email"));
        MySwal.fire({
          titleText: t("error.swal_unverified_email.title"),
          text: `${t("error.swal_unverified_email.text")} ${user.user.email} ?`,
          icon: "question",
          showCancelButton: true,
          confirmButtonText: t("error.swal_unverified_email.resend_button"),
        })
          .then(async (result) => {
            if (result.isConfirmed) {
              //resend email
              await sendEmailVerification(user.user);
              logout();
            } else {
              // nothing
              logout();
            }
          })
          .catch((err) => {
            console.error(err);
          });
        customMessage.code = "auth/email-not-verified";
        throw customMessage;
      }
    } catch (error) {
      let customError = new Error();
      console.error(error);
      switch (error.code) {
        case "auth/invalid-email":
          customError.message = t("error.invalid_email");
          break;
        case "auth/invalid-credential":
          customError.message = t("error.invalid_credential");
          break;
        case "auth/user-not-found":
          customError.message = t("error.user_not_found");
          break;
        case "auth/wrong-password":
          customError.message = t("error.wrong_password");
          break;
        case "auth/too-many-requests":
          customError.message = t("error.too_much_request");
          break;
        case "auth/email-not-verified":
          customError.message = t("error.unverified_email");
          break;

        default:
          customError.message = t("error.unable_login");
          break;
      }
      customError.code = error.code;
      Perf_login.putAttribute("error", customError.message);
      throw customError;
    } finally {
      Perf_login.stop();
    }
  };

  const logout = async () => {
    const Perf_logout = createTrace(perf, "logout");
    Perf_logout.start();
    try {
      await signOut(auth);
      queryClient.invalidateQueries("currentUser");
      toast.info(t("toast.logout"), { toastId: "loggedOut" });
      Perf_logout.stop();
    } catch (error) {
      console.error(error);
      Perf_logout.putAttribute("error", error.message);
      Perf_logout.stop();
      throw error;
    }
  };

  const checkActiveSession = async () => {
    // perf already set
    try {
      if (currentUser) {
        const docRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          throw new Error(t("error.unable_retrieve_data"));
        }
        const docData = docSnap.data();
        if (docData.asActiveSession) {
          const quizRef = doc(db, "quizSessions", docData.sessionId);
          const quizSnap = await getDoc(quizRef);

          if (!quizSnap.exists()) {
            throw new Error(t("error.unable_retrieve_data"));
          }
          const quizData = quizSnap.data();
          const gap = timeStampGap(Date.now(), quizData.createdAt);
          if (gap >= 10) {
            return { active: true, sessionId: docData.sessionId, closed: true };
          }
          if (quizData.quizState === "waiting") {
            return {
              active: true,
              sessionId: docData.sessionId,
              closed: false,
            };
          } else {
            return { active: true, sessionId: docData.sessionId, closed: true };
          }
        } else return { active: false };
      }
    } catch (error) {
      try {
        await leftSession();
      } catch (err) {
        console.error(error);
        throw error;
      }
    }
  };

  async function askForQuiz() {
    // perf already set
    async function randomQuiz() {
      // will not work because no permission
      try {
        const keysDocRef = doc(db, "quizDB", "00keys");
        const keysDoc = await getDoc(keysDocRef);

        if (!keysDoc.exists()) throw new Error(t("error.unable_retrieve_data"));

        const keys = keysDoc.data().keysArray;
        const randomIndex = Math.floor(Math.random() * keys.length);
        const choosedKey = keys[randomIndex];
        const quizRef = doc(db, "quizDB", choosedKey);
        const quizData = await getDoc(quizRef);

        if (!quizData.exists()) throw new Error(t("error.unable_load_quiz"));

        return quizData.data();
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    async function sessionQuiz(sessionId) {
      try {
        const quizRef = doc(
          db,
          "quizSessions",
          sessionId,
          "quizQuestions",
          "questions"
        );
        const quizQuestions = await getDoc(quizRef);

        if (!quizQuestions.exists())
          throw new Error(t("error.unable_load_quiz"));

        return quizQuestions.data();
      } catch (error) {
        console.error(error);
      }
    }

    try {
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const getUserDoc = await getDoc(userDocRef);

        if (getUserDoc.exists() && getUserDoc.data().sessionId) {
          return await sessionQuiz(getUserDoc.data().sessionId);
        } else {
          return await randomQuiz();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const leftSession = async () => {
    // perf already set
    const tryLeftSession = httpsCallable(functions, "leftSession");

    try {
      const leftSession = await tryLeftSession();
      if (leftSession.data.status === 200) {
      } else throw new Error(t("error.unable_left_session"));
      return;
    } catch (error) {
      console.error(error);
      toast.error({
        render: t("error.unable_left_session"),
        toastId: "leftSession",
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isLoading,
        signup,
        login,
        logout,
        checkActiveSession,
        askForQuiz,
        leftSession,
        createTrace,
        MySwal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const UseAuth = () => {
  return React.useContext(AuthContext);
};
