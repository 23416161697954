import React from "react";

const color = window.screen.width <= 600 ? "#f3da68" : "#f7e8a4";

export const CrownLogo = (props) => (
  <svg
    height="200"
    width="200"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fillRule="evenodd">
      <g fill="#c8832e">
        <path d="M22.07 17.472c0 1.614-1.255 2.919-2.801 2.919c-1.55 0-2.801-1.305-2.801-2.919c0-1.612 1.251-2.923 2.801-2.923c1.547 0 2.801 1.31 2.801 2.923" />
        <path d="M15.353 29.21s6.567-4.072 3.215-12.305c0 0 11.883 17.11 15.238 9.83c3.354-7.281-.979 8.885-1.258 9.32c-.279.439-5.871 4.808-5.871 4.808h-8.806L15.353 29.21" />
        <path d="M48.647 29.21s-6.571-4.072-3.215-12.305c0 0-11.884 17.11-15.24 9.83c-3.353-7.281.981 8.885 1.263 9.32c.274.439 5.867 4.808 5.867 4.808h8.81l2.515-11.653" />
        <ellipse cx="44.735" cy="17.472" rx="2.804" ry="2.919" />
      </g>
      <path
        d="M60.07 14.566c-2.146 0-3.884 1.907-3.884 4.263c0 .628.13 1.224.353 1.76c-6.01 3.454-11.595 8.788-11.595 8.788c-5.079 5.863-9.192-3.694-11.435-11.02c2.732-.771 4.757-3.488 4.757-6.73c0-3.843-2.836-6.958-6.338-6.958c-3.501 0-6.338 3.115-6.338 6.958c0 3.293 2.085 6.04 4.886 6.768c-2.248 7.327-6.354 16.843-11.42 10.987c0 0-5.585-5.334-11.601-8.788a4.562 4.562 0 0 0 .355-1.76c0-2.356-1.74-4.263-3.884-4.263c-2.145 0-3.884 1.907-3.884 4.263s1.74 4.265 3.884 4.265c.72 0 1.383-.229 1.963-.604c2.244 4.679 5.412 13.09 3.758 20.804c0 0 2.435 9.755 22.351 9.426c19.915.329 22.347-9.426 22.347-9.426c-1.652-7.711 1.515-16.12 3.761-20.804c.58.375 1.243.604 1.963.604c2.145 0 3.885-1.909 3.885-4.265s-1.739-4.264-3.884-4.264"
        fill="#f79420"
      />
      <path
        d="M32 54.955c-15.913.263-20.662-5.909-21.927-8.402a21.03 21.03 0 0 1-.424 3.347S12.084 59.653 32 59.328c19.915.325 22.347-9.428 22.347-9.428a21.011 21.011 0 0 1-.422-3.347c-1.265 2.493-6.01 8.665-21.925 8.402"
        fill="#c8832e"
      />
      <path
        d="M31.1 52.614c2.858-14.365 4.01-29.03-.271-43.3c-.488-1.624-.616-3.138-.475-4.531c-2.74.769-4.765 3.486-4.765 6.734c0 3.293 2.085 6.05 4.886 6.766c-2.248 7.329-6.354 16.847-11.42 10.989c0 0-5.585-5.334-11.601-8.789a4.56 4.56 0 0 0 .355-1.761c0-2.352-1.74-4.261-3.884-4.261c-2.145 0-3.884 1.909-3.884 4.261c0 2.358 1.74 4.267 3.884 4.267c.72 0 1.383-.231 1.963-.604c2.244 4.676 5.412 13.09 3.758 20.801c0 0 2.375 9.447 21.454 9.431"
        fill="#f19d43"
      />
    </g>
    <g fill="#fff">
      <path d="m32 40.26l1.692 3.16l3.777.504l-2.737 2.456l.648 3.471L32 48.21l-3.376 1.641l.646-3.471l-2.734-2.456l3.774-.504z" />
      <path d="M31.938 41.922a7.952 7.952 0 0 1 .096-1.598l-.031-.064l-1.691 3.158l-3.776.506l2.73 2.454l-.643 3.473l3.38-1.638l3.375 1.638l-.272-1.474c-1.927-1.679-3.073-4.01-3.168-6.455m13.272.053l1.04 1.933l2.312.312l-1.671 1.503l.389 2.131l-2.07-1l-2.07 1l.396-2.131l-1.674-1.503l2.318-.312z" />
      <path d="M45.17 42.992a4.904 4.904 0 0 1 .061-.979l-.02-.039l-1.035 1.934l-2.315.311l1.674 1.504l-.393 2.131l2.069-1l2.069 1l-.166-.904c-1.181-1.031-1.884-2.463-1.944-3.958m-26.466-1.017l1.033 1.933l2.313.312l-1.673 1.503l.396 2.131l-2.069-1l-2.07 1l.396-2.131l-1.677-1.503l2.313-.312z" />
      <path d="M18.662 42.992a4.317 4.317 0 0 1 .063-.979l-.021-.039l-1.038 1.934l-2.313.311l1.673 1.504l-.393 2.131l2.07-1l2.069 1l-.168-.904c-1.181-1.031-1.883-2.463-1.942-3.958" />
    </g>
  </svg>
);

export const LogoutLogo = (props) => (
  <svg
    height="200"
    width="200"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" stroke="#ff495c" strokeLinecap="round" strokeWidth="1.5">
      <path
        d="M13.477 21.245H8.34a4.918 4.918 0 0 1-5.136-4.623V7.378A4.918 4.918 0 0 1 8.34 2.755h5.136"
        strokeLinejoin="round"
      />
      <path d="M20.795 12H7.442" strokeMiterlimit="10" />
      <path
        d="m16.083 17.136l4.404-4.404a1.04 1.04 0 0 0 0-1.464l-4.404-4.404"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const CloseLogo = (props) => (
  <svg
    height="200"
    width="200"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.48 3.512a11.966 11.966 0 0 0-8.486-3.514C5.366-.002-.007 5.371-.007 11.999c0 3.314 1.344 6.315 3.516 8.487A11.966 11.966 0 0 0 11.995 24c6.628 0 12.001-5.373 12.001-12.001c0-3.314-1.344-6.315-3.516-8.487zm-1.542 15.427a9.789 9.789 0 0 1-6.943 2.876c-5.423 0-9.819-4.396-9.819-9.819a9.789 9.789 0 0 1 2.876-6.943a9.786 9.786 0 0 1 6.942-2.876c5.422 0 9.818 4.396 9.818 9.818a9.785 9.785 0 0 1-2.876 6.942z"
      fill="#ff495c"
    />
    <path
      d="m13.537 12l3.855-3.855a1.091 1.091 0 0 0-1.542-1.541l.001-.001l-3.855 3.855l-3.855-3.855A1.091 1.091 0 0 0 6.6 8.145l-.001-.001l3.855 3.855l-3.855 3.855a1.091 1.091 0 1 0 1.541 1.542l.001-.001l3.855-3.855l3.855 3.855a1.091 1.091 0 1 0 1.542-1.541l-.001-.001z"
      fill="#ff495c"
    />
  </svg>
);

export const TrophyLogo = (props) => (
  <svg
    height="200"
    width="200"
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.123 5h6C12.227 5 13 4.896 13 6V4c0-1.104-.773-2-1.877-2h-8c-2 0-3.583 2.125-3 5c0 0 1.791 9.375 1.917 9.958C2.373 18.5 4.164 20 6.081 20h6.958c1.105 0-.039-1.896-.039-3v-2c0 1.104-.773 2-1.877 2h-4c-1.104 0-1.833-1.042-2-2S3.539 7.667 3.539 7.667C3.206 5.75 4.018 5 5.123 5zm25.812 0h-6C23.831 5 22 4.896 22 6V4c0-1.104 1.831-2 2.935-2h8c2 0 3.584 2.125 3 5c0 0-1.633 9.419-1.771 10c-.354 1.5-2.042 3-4 3h-7.146C21.914 20 22 18.104 22 17v-2c0 1.104 1.831 2 2.935 2h4c1.104 0 1.834-1.042 2-2s1.584-7.333 1.584-7.333C32.851 5.75 32.04 5 30.935 5zM20.832 22c0-6.958-2.709 0-2.709 0s-3-6.958-3 0s-3.291 10-3.291 10h12.292c-.001 0-3.292-3.042-3.292-10z"
      fill="#FFAC33"
    />
    <path
      d="M29.123 6.577c0 6.775-6.77 18.192-11 18.192c-4.231 0-11-11.417-11-18.192c0-5.195 1-6.319 3-6.319c1.374 0 6.025-.027 8-.027l7-.001c2.917-.001 4 .684 4 6.347z"
      fill="#FFCC4D"
    />
    <path
      d="M27 33c0 1.104.227 2-.877 2h-16C9.018 35 9 34.104 9 33v-1c0-1.104 1.164-2 2.206-2h13.917c1.042 0 1.877.896 1.877 2v1z"
      fill="#C1694F"
    />
    <path
      d="M29 34.625c0 .76.165 1.375-1.252 1.375H8.498C7.206 36 7 35.385 7 34.625v-.25C7 33.615 7.738 33 8.498 33h19.25c.759 0 1.252.615 1.252 1.375v.25z"
      fill="#C1694F"
    />
  </svg>
);

export const SocialX = (props) => (
  <svg
    height="200"
    width="200"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.751 3h3.067l-6.7 7.625L22 21h-6.172l-4.833-6.293L5.464 21h-3.07l7.167-8.155L2 3h6.328l4.37 5.752zm-1.076 16.172h1.7L7.404 4.732H5.58z"
      fill="black"
    />
  </svg>
);

export const QuestionMark = (props) => (
  <svg
    height="200"
    width="200"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" stroke="black">
      <circle cx="12" cy="12" r="9" strokeWidth="2" />
      <circle cx="12" cy="18" fill="black" r=".5" />
      <path
        d="M12 16v-1.419c0-.944.604-1.782 1.5-2.081a2.194 2.194 0 0 0 1.5-2.081v-.513C15 8.3 13.7 7 12.094 7H12a3 3 0 0 0-3 3"
        strokeWidth="2"
      />
    </g>
  </svg>
);
