import "./Styles/App.scss";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import { useLocation, useRoutes, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { UseAuth } from "./Components/utils/firebase.jsx";
import Home from "./Pages/Home/Home.jsx";

const Quizparty = React.lazy(() => import("./Pages/QuizParty/Quizparty.jsx"));
const SoloQuiz = React.lazy(() => import("./Pages/SoloQuiz/SoloQuiz.jsx"));
const WaitingRoom = React.lazy(() =>
  import("./Pages/WaitingRoom/WaitingRoom.jsx")
);
const Game = React.lazy(() => import("./Pages/QuizGame/Game.jsx"));
const JoinGames = React.lazy(() => import("./Pages/JoinGames/JoinGames.jsx"));
const CreateGame = React.lazy(() =>
  import("./Pages/CreateGame/CreateGame.jsx")
);
const Leaderboard = React.lazy(() =>
  import("./Pages/Leaderboard/Leaderboard.jsx")
);

function App() {
  const { t } = useTranslation();
  const [cookiesAccepted, setCookiesAccepted] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, isLoading, logout } = UseAuth();

  useEffect(() => {
    setCookiesAccepted(localStorage.getItem("accept_cookies"));
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });

    if (!isLoading && currentUser && !currentUser.emailVerified) {
      logout();
    }

    if (
      (!isLoading && !currentUser && location.pathname !== "/") ||
      (!isLoading &&
        currentUser &&
        !currentUser.emailVerified &&
        location.pathname !== "/")
    ) {
      toast.error(t("toast.logged_and_email_verified"), {
        toastId: "notLogged",
      });
      navigate("/");
    }
  }, [location.pathname, currentUser]);

  const element = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "QuizParty",
      element: <Quizparty />,
    },
    {
      path: "QuizParty/SoloQuiz",
      element: <SoloQuiz />,
    },
    {
      path: "QuizParty/Game",
      element: <Game />,
    },
    {
      path: "QuizParty/CreateGame",
      element: <CreateGame />,
    },
    { path: "QuizParty/WaitingRoom", element: <WaitingRoom /> },
    {
      path: "QuizParty/JoinGame",
      element: <JoinGames />,
    },
    {
      path: "QuizParty/Leaderboard",
      element: <Leaderboard />,
    },
  ]);

  if (!element) return null;

  return (
    <div style={{ justifyContent: "center" }}>
      <AnimatePresence mode="wait" initial={false}>
        <div key={location.key}>{element}</div>
      </AnimatePresence>
      {cookiesAccepted === null && (
        <div className="acceptCookiesPrompt">
          <div className="infoContainer">
            <div className="infoContent">
              <div> {t("privacy_policy.part_one")}</div>
              <div className="privacyContainer">
                <div>{t("privacy_policy.part_two")}</div>
                <div
                  style={{
                    color: "rgb(0 100 173)",
                    fontWeight: 700,
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginLeft: "0.2rem",
                  }}
                  onClick={() => {
                    window.open(
                      "https://fire-chef-a22.notion.site/Privacy-Policy-3d2e83648bd94f37aa920f6db84f64b3",
                      "_blank"
                    );
                  }}
                >
                  {t("privacy_policy.part_three")}
                </div>
              </div>
            </div>
          </div>
          <div style={{ gap: "1rem" }}>
            <button
              onClick={() => {
                localStorage.setItem("accept_cookies", true);
                setCookiesAccepted(true);
                window.location.reload();
              }}
              className="acceptButton"
            >
              {t("privacy_policy.accept_button")}
            </button>
            <button
              onClick={() => {
                localStorage.setItem("accept_cookies", false);
                setCookiesAccepted(false);
                window.location.reload();
              }}
              className="declineButton"
            >
              {t("privacy_policy.decline_button")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
