import React, { useEffect, useState } from "react";
import "./Home.scss";
import { useNavigate } from "react-router";
import { db } from "../../Components/utils/firebase";
import { getDoc, doc } from "firebase/firestore";
import { SocialX, QuestionMark } from "../../Components/Logo";
import { motion, useIsPresent } from "framer-motion";
import { UseAuth } from "../../Components/utils/firebase";
import { toast } from "react-toastify";
import MyAccount from "../../Components/MyAccount/MyAccount";
import { useTranslation } from "react-i18next";

const Home = () => {
  const user_language = navigator.language || navigator.userLanguage;
  const { currentUser, MySwal } = UseAuth();
  const [userData, setUserData] = useState({});
  const isPresent = useIsPresent();
  const navigate = useNavigate();
  const [showMyAccount, setShowMyAccount] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (currentUser) getUserPublicData();

    async function getUserPublicData() {
      const userPublicDocRef = doc(
        db,
        "users",
        currentUser.uid,
        "public",
        "userInfo"
      );
      const result = await getDoc(userPublicDocRef);
      if (result.exists) {
        setUserData(result.data());
      }
    }
  }, [currentUser]);

  useEffect(() => {
    [
      "temp0",
      "temp1",
      "temp2",
      "isRefreshing",
      "tempSess",
      "isInSession",
    ].forEach((key) => {
      localStorage.removeItem(key);
    });
  }, []);

  function calculateScoreGap() {
    const score = userData.score;
    const level = userData.level;
    const actualLevelXP = level ** 2 * 200;
    const nextLevelXP = (level + 1) ** 2 * 200;
    const percent =
      ((score - actualLevelXP) / (nextLevelXP - actualLevelXP)) * 100;
    return percent;
  }

  function calculateNextLvlXP() {
    const level = userData.level;
    const nextLevelXP = (level + 1) ** 2 * 200;
    return nextLevelXP.toLocaleString(user_language);
  }

  return (
    <div
      className="unselectable"
      style={{
        justifyContent: "center",
        flexDirection: "column",
        gap: "10vh",
        marginBottom: "auto",
      }}
    >
      {currentUser && (
        <div className="levelModal">
          <div className="levelContainer">
            <div className="mainLevel">
              <div className="levelText">{t("home.level")}</div>
              <div className="currentLevel">{userData.level || 0}</div>
            </div>

            <div className="progressBarContainer">
              <div className="levelNumbers">
                <span className="actualLevel">{userData.level || 0}</span>
                <span className="levelGap">
                  {`${(userData.score || 0).toLocaleString(
                    user_language
                  )} / ${calculateNextLvlXP()}`}
                </span>

                <span className="nextLevel">{userData.level + 1 || 1}</span>
              </div>
              <div className="progressBarBackground">
                <div
                  className="progressBarFill"
                  style={{
                    width: `${calculateScoreGap() || 0}%`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
      <button
        className="gameButton"
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          fontSize: "1.2rem",
          margin: "1rem",
        }}
        onClick={() => setShowMyAccount((prev) => !prev)}
      >
        {currentUser ? t("home.my_account_button") : t("home.connect_button")}
      </button>
      {showMyAccount && (
        <MyAccount key="myAccount" onClose={() => setShowMyAccount(false)} />
      )}{" "}
      <div className="unselectable" style={{ justifyContent: "center" }}>
        <img
          src="./assets/quitop.webp"
          alt="quitop logo"
          className="quitopLogoHD"
        />
        <link
          rel="preload"
          href="./assets/quitop.webp"
          alt="quitop banner"
          as="image"
        ></link>
      </div>
      <div className="gamesMenu">
        <button
          onClick={() =>
            currentUser
              ? navigate("Quizparty")
              : toast.error("You need to login", { toastId: "notLogged" })
          }
          className="selectGame"
        >
          {t("home.quiz_button")}
        </button>
      </div>
      <div className="socialContainer">
        <SocialX
          onClick={() => {
            window.open("https://x.com/Quitop_Games", "_blank");
          }}
          height="45"
          width="45"
          style={{
            cursor: "pointer",
          }}
        />
      </div>
      <div className="helpContainer">
        <QuestionMark
          height="45"
          width="45"
          style={{ cursor: "pointer" }}
          onClick={() => {
            MySwal.fire({
              title: t("home.help.title"),
              html: t("home.help.message"),
              icon: "info",
              confirmButtonText: t("home.help.comfirm_button"),
              sanitize: true,
              allowedAttributes: {
                a: ["href", "style"], // Permet l'attribut style et href pour les balises <a>
              },
            });
          }}
        />
      </div>
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 0 : 1 }}
        className="privacy-screen"
      />
    </div>
  );
};

export default Home;
