import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Importer les fichiers de traduction
import translationEN from "../../Languages/English.json";
import translationFR from "../../Languages/French.json";

// Configuration de i18n
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // Pour utiliser avec React
  .init({
    detection: {
      order: ["navigator", "cookie", "localStorage"], // Priorise la langue du navigateur
      caches: ["cookie", "localStorage"], // Stocker la langue dans les cookies ou localStorage
    },
    resources: {
      en: {
        translation: translationEN,
      },
      fr: {
        translation: translationFR,
      },
    },
    fallbackLng: "en", // Langue de secours si la traduction est manquante
    interpolation: {
      escapeValue: false, // React gère déjà l'échappement des valeurs
    },
  });

export default i18n;
