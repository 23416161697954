import React, { useEffect, useState } from "react";
import "./MyAccount.scss";
import { db } from "../utils/firebase";
import { getDoc, doc } from "firebase/firestore";
import { UseAuth } from "../utils/firebase";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { CloseLogo, LogoutLogo } from "../Logo";
import { useTranslation } from "react-i18next";
const MyAccount = ({ onClose }) => {
  const user_language = navigator.language || navigator.userLanguage;
  const [wichForms, setWichForms] = useState("login");
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");
  const [blockForm, setBlockForm] = useState(false);
  const [userDocData, setUserDocData] = useState({});
  const { isLoading, signup, login, currentUser, logout } = UseAuth();
  const { t } = useTranslation();

  useEffect(() => {
    async function getUserData() {
      const userDocRef = doc(db, "users", currentUser.uid);
      const userDocData = await getDoc(userDocRef);
      setUserDocData(userDocData.data());
    }

    if (!isLoading && currentUser) {
      getUserData();
    }
  }, [isLoading]);

  const invalidLoginForm = (event) => {
    event.preventDefault();
    if (email === "" || password === "") {
      const elem = event.target;
      elem.style.border = "2px solid #fe585d";
      setTimeout(() => {
        elem.style.border = "";
      }, 2000);
      return;
    }
    if (event.target.type === "email" && event.target.validity.typeMismatch) {
      toast.error(t("toast.invalid_email"), { toastId: "invalidEmail" });
    }
  };

  const invalidSigninForm = (event) => {
    event.preventDefault();
    if (email === "" || password === "" || confirmPassword === "") {
      const elem = event.target;
      elem.style.border = "2px solid #fe585d";
      setTimeout(() => {
        elem.style.border = "";
      }, 2000);
      return;
    }
    if (event.target.type === "email" && event.target.validity.typeMismatch) {
      toast.error(t("toast.invalid_email"), { toastId: "invalidEmail" });
    }
    if (event.target.type === "checkbox") {
      toast.error(t("toast.uncheck_gcu"), { toastId: "uncheckGCU" });
    }
  };

  const submitLoginForm = async (e) => {
    e.preventDefault();
    if (blockForm)
      return toast(t("toast.wait_please"), { toastId: "WaitPlease" });
    setBlockForm(true);
    const tryLogin = new Promise(async (resolve, reject) => {
      try {
        await login(email, password);
        onClose();
        setBlockForm(false);
        resolve();
      } catch (error) {
        setBlockForm(false);
        onClose();

        reject(error);
      }
    });
    toast.promise(tryLogin, {
      pending: { render: t("toast.try_login"), toastId: "tryLogin" },
      success: { render: t("toast.logged"), toastId: "tryLogin" },
      error: { render: ({ data }) => data.message, toastId: "tryLogin" },
    });
  };

  const submitSigninForm = (event) => {
    event.preventDefault();
    if (blockForm) return toast("Wait please");
    const tryRegister = new Promise(async (resolve, reject) => {
      try {
        await signup(email, password, username);
        onClose();
        setBlockForm(false);
        resolve();
      } catch (error) {
        setBlockForm(false);
        reject(error);
      }
    });
    toast.promise(tryRegister, {
      pending: { render: "Try Register...", toastId: "tryRegister" },
      success: {
        render: t("toast.registered"),
        toastId: "registered",
      },
      error: { render: ({ data }) => data.message, toastId: "tryRegister" },
    });
  };

  return currentUser ? (
    <motion.div
      onClick={onClose}
      className="manageAccountModal unselectable"
      animate={{ opacity: 1 }}
      transition={{ ease: "linear", duration: 0.15 }}
    >
      <div onClick={(e) => e.stopPropagation()} className="loginModalContainer">
        <CloseLogo
          onClick={onClose}
          height="45"
          width="45"
          style={{
            position: "absolute",
            top: "0.3rem",
            right: "0.3rem",
            cursor: "pointer",
          }}
        />
        <LogoutLogo
          onClick={() => logout()}
          height="50"
          width="50"
          style={{
            position: "absolute",
            top: "0.1rem",
            left: "0.1rem",
            cursor: "pointer",
          }}
        />
        <div
          style={{
            fontSize: "1.6rem",
            color: "#bababa",
            alignSelf: "center",
            fontWeight: "bold",
          }}
        >
          {t("home.account_modal.connected.head_title")}
        </div>
        <div className="personnalInfo">
          <div className="infoContainer">
            <div className="infoName">
              {t("home.account_modal.connected.username_label")}
            </div>
            <div className="infoData"> {currentUser.displayName || ""}</div>
          </div>
          <div className="infoContainer">
            <div className="infoName">
              {t("home.account_modal.connected.email_label")}
            </div>
            <div className="infoData"> {currentUser.email || ""}</div>
          </div>
        </div>
        <div className="gameInfo">
          <div className="statsContainer">
            <div className="statsTitle">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Luckiestguy",
                  justifyContent: "center",
                  fontSize: "2rem",
                  margin: "1rem 0rem",
                  gap: "0.5rem",
                }}
              >
                {t("home.account_modal.connected.stats_title")}
              </div>
              <div className="statsTable">
                <div className="statsHeader">
                  <div className="statsColHeader">
                    {t("home.account_modal.connected.type_column_title")}
                  </div>
                  <div className="statsColHeader">
                    {t("home.account_modal.connected.value_column_title")}
                  </div>
                </div>

                <div className="statsRow">
                  <div className="statsColumn">
                    {t("home.account_modal.connected.game_played")}
                  </div>
                  <div className="statsColumn">
                    {(userDocData.gamePlayed || 0).toLocaleString(
                      user_language
                    )}
                  </div>
                </div>
                <div className="statsRow">
                  <div className="statsColumn">
                    {t("home.account_modal.connected.score")}
                  </div>
                  <div className="statsColumn">
                    {(userDocData.score || 0).toLocaleString(user_language)}
                  </div>
                </div>
                <div className="statsRow">
                  <div className="statsColumn">
                    {t("home.account_modal.connected.average_score")}
                  </div>
                  <div className="statsColumn">
                    {(userDocData.averageScorePerGame || 0).toLocaleString(
                      user_language
                    )}
                  </div>
                </div>
                <div className="statsRow">
                  <div className="statsColumn">
                    {t("home.account_modal.connected.perfect_game")}
                  </div>
                  <div className="statsColumn">
                    {(userDocData.perfectGame || 0).toLocaleString(
                      user_language
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  ) : (
    <motion.div
      onClick={onClose}
      className="loginModal unselectable"
      animate={{ opacity: 1 }}
      transition={{ ease: "linear", duration: 0.15 }}
    >
      <div onClick={(e) => e.stopPropagation()} className="loginModalContainer">
        <CloseLogo
          onClick={onClose}
          height="45"
          width="45"
          style={{
            position: "absolute",
            top: "0.3rem",
            right: "0.3rem",
            cursor: "pointer",
          }}
        />
        <div
          className="formSwitchContainer"
          style={{ display: "flex", width: "100%" }}
        >
          <h1
            className={`unselectable ${wichForms === "login" ? "active" : ""}`}
            onClick={() => {
              setWichForms("login");
              setEmail("");
              setpassword("");
            }}
          >
            {t("home.account_modal.not_connected.login_title")}
          </h1>
          <h1
            className={`unselectable ${wichForms === "signup" ? "active" : ""}`}
            onClick={() => {
              setWichForms("signup");
              setEmail("");
              setpassword("");
            }}
          >
            {t("home.account_modal.not_connected.register_title")}
          </h1>
        </div>
        <div
          style={{
            transform:
              wichForms === "login" ? "translateX(0%)" : "translateX(100%)",
          }}
          className="underline unselectable"
        ></div>
        <div style={{ display: "flex", height: "100%", width: "100%" }}>
          {wichForms === "login" ? (
            <div style={{ gap: "1rem" }} className="unselectable login">
              <form
                className="loginForm"
                onInvalid={(e) => invalidLoginForm(e)}
                onSubmit={(e) => {
                  submitLoginForm(e);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      alignSelf: "baseline",
                      left: "9%",
                      color: "#bababa",
                      fontWeight: 800,
                    }}
                  >
                    {t("home.account_modal.not_connected.email_label")}
                  </div>
                  <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                      width: "80%",
                      marginBottom: "2vh",
                      textAlign: "center",
                      border: 0,
                      borderRadius: "0.2rem",
                    }}
                    value={email}
                    required
                    autoComplete="email"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      alignSelf: "baseline",
                      left: "9%",
                      color: "#bababa",
                      fontWeight: 800,
                    }}
                  >
                    {t("home.account_modal.not_connected.password_label")}
                  </div>
                  <input
                    type="password"
                    onChange={(e) => setpassword(e.target.value)}
                    style={{
                      width: "80%",
                      textAlign: "center",
                      letterSpacing: "0.3rem",
                      border: 0,
                      borderRadius: "0.2rem",
                    }}
                    value={password}
                    required
                    autoComplete="current-password"
                  />
                </div>
                <input
                  type="submit"
                  value={t("home.account_modal.not_connected.login_button")}
                  className="gameButton"
                  disabled={blockForm}
                  style={{
                    fontSize: "1.3rem",
                    alignSelf: "center",
                  }}
                />
              </form>
            </div>
          ) : (
            <div className="unselectable signup">
              <form
                className="signupForm"
                onInvalid={(e) => invalidSigninForm(e)}
                onSubmit={(e) => submitSigninForm(e)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                  }}
                >
                  <label
                    htmlFor="username"
                    style={{ color: "#bababa", fontWeight: 800 }}
                  >
                    {t("home.account_modal.not_connected.username_label")}
                  </label>
                  <input
                    id="username"
                    name="username"
                    type="text"
                    style={{
                      textAlign: "center",
                      border: 0,
                      borderRadius: "0.2rem",
                    }}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    autoComplete="username"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                  }}
                >
                  <label
                    htmlFor="email"
                    style={{ color: "#bababa", fontWeight: 800 }}
                  >
                    {t("home.account_modal.not_connected.email_label")}
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    style={{
                      textAlign: "center",
                      border: 0,
                      borderRadius: "0.2rem",
                    }}
                    autoComplete="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                  }}
                >
                  <label
                    htmlFor="password"
                    style={{ color: "#bababa", fontWeight: 800 }}
                  >
                    {t("home.account_modal.not_connected.password_label")}
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    style={{
                      textAlign: "center",
                      letterSpacing: "0.3rem",
                      border: 0,
                      borderRadius: "0.2rem",
                    }}
                    required
                    onChange={(e) => setpassword(e.target.value)}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                  }}
                >
                  <label
                    htmlFor="confirmPassword"
                    style={{ color: "#bababa", fontWeight: 800 }}
                  >
                    {t(
                      "home.account_modal.not_connected.comfirm_password_label"
                    )}
                  </label>
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="new-password"
                    value={confirmPassword}
                    style={{
                      textAlign: "center",
                      letterSpacing: "0.3rem",
                      border: 0,
                      borderRadius: "0.2rem",
                    }}
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "0.3rem",
                    marginTop: "1rem",
                    marginBottom: "1.rem",
                  }}
                >
                  <label className="labelGCU" htmlFor="Accept_GCU">
                    <div style={{ color: "#bababa" }}>
                      {t("home.account_modal.not_connected.accept_gcu_text")}
                    </div>{" "}
                    <div
                      className="GCU"
                      onClick={() => {
                        window.open(
                          "https://fire-chef-a22.notion.site/General-Conditions-of-Use-5868ed02eeba4910b1d38091a19823aa",
                          "_blank"
                        );
                      }}
                    >
                      {t("home.account_modal.not_connected.accept_gcu_link")}
                    </div>
                  </label>
                  <input type="checkbox" name="Accept_GCU" required />
                </div>

                <input
                  type="submit"
                  value={t("home.account_modal.not_connected.register_button")}
                  className="gameButton"
                  disabled={blockForm}
                  style={{ fontSize: "1.3rem" }}
                />
              </form>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default MyAccount;
